<template>
  <div id="Bottom" class="layout">
    경로 :
    <ul class="path">
      <li v-for="item in pathList" :key="item.folder_id" @click="openFolder(item.oriItem)">{{item.folder_name}}</li>
    </ul>
  </div>
</template>

<script>
import {EventBus} from "@/utils/EventBus";
import {mapGetters} from "vuex";
export default {
  data(){
    return{
      pathList : [{folder_id:null, folder_name: "/"}]
    }
  },
  computed: {
    ...mapGetters({ info: "getSaveInfo" }),
  },
  created(){
    let objThis = this
    EventBus.$on("bus:openFolder", function(item){
      objThis.foundPath(item)
    })
  },
  methods:{
    foundPath(item){
      let objThis = this
      let oriTree = JSON.parse(JSON.stringify(this.info.folder))
      let treeToArray = function(tree, path){
        let arr = []
        tree.forEach(item=>{
          let oriItem = JSON.parse(JSON.stringify(item))
          let cPath = JSON.parse(JSON.stringify(path))
          cPath.push({folder_id: item.folder_id, folder_name: item.folder_name, oriItem: oriItem})
          item.path = cPath
          if(item.children && item.children.length > 0){
            let children = JSON.parse(JSON.stringify(item.children))
            delete item.children
            let cArr = treeToArray(children, cPath)
            arr.push(...cArr)
          }
          arr.push(item)
        })
        return arr
      }
      if(item.folder_id == null){
        this.pathList = [{folder_id:null, folder_name: "/"}]
      }else{
        let arr = treeToArray(oriTree, [{folder_id:null, folder_name: "/"}])
        arr.forEach(el=>{
          if(el.folder_id == item.folder_id){
            this.pathList = el.path
          }
        })
      }
    },
    openFolder(item){
      item = item || {folder_id: null, children: this.info.folder}
      EventBus.$emit("bus:openFolder", item)
    },
  }
};
</script>

<style scoped>
ul.path{
  display: inline;
}
ul.path li{
  display:inline;
  cursor: pointer;
}
ul.path li:after{
  content: ">";
  color: #aaa;
  font-weight: bold;
  margin: 0 8px;
}
ul.path li:last-child{
  color: #4778D9;
  font-weight: bold;
}
ul.path li:last-child:after{
  content: "";
}
</style>
